type LocalizeMethod = (key: string, args?: Record<string, unknown>) => string

export function useLocalize (namespace: string = ''): LocalizeMethod & {
  /**
   * Localize key without prepending current namespace
   */
  global: LocalizeMethod
} {
  const localize = (key: string, args: Record<string, unknown> = {}) => window.localizeText(namespace ? `${namespace}.${key}` : key, args)
  localize.global = (key: string, args: Record<string, unknown> = {}) => window.localizeText(key, args)

  return localize
}